import { flockTheme, LibraryThemeProvider } from '@flock/shared-ui'
import { ThemeProvider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DocumentRenderer from '../../../components/DocumentComponents/DocumentRenderer/DocumentRenderer'

type DocumentProps = {
  params: {
    lead: string
  }
}

const Document = (props: DocumentProps) => {
  const { params } = props
  const { lead } = params
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    setShouldRender(true)
  }, [])

  return (
    <ThemeProvider theme={flockTheme}>
      <LibraryThemeProvider>
        {shouldRender && <DocumentRenderer leadId={lead} />}
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default Document
